import React from 'react';
import { MdHome, MdOutlineNotifications } from 'react-icons/md';
import { JobStatusStrings } from '@epcbuilder/lib/models/jobs';
import { Property } from '@epcbuilder/lib/models/properties';
import { capitalize } from '@epcbuilder/lib/utils/generic';
import { format } from 'date-fns';
import { useBelowTablet } from '../../hooks/media-queries/useMediaQueries';

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const getPropertyOwnerInitials = (userFullName: string | undefined) => {
  if (userFullName) {
    const nameArray = userFullName!.split(' ');
    const initials = nameArray.shift()!.charAt(0) + nameArray.pop()?.charAt(0);
    return initials.toUpperCase();
  }

  return 'NA';
};

const getFontColour = (backgroundColor: string) => {
  const background = backgroundColor;
  const rgb = hexToRgb(background);
  const luminance = (0.299 * rgb!.r + 0.587 * rgb!.g + 0.114 * rgb!.b) / 255;

  if (luminance.valueOf() >= 0.5) {
    return 'black'; // bright color - dark font
  } else {
    return 'white'; // dark color - light font
  }
};

export const PropertyOwnerInitialsData = ({ property, className }: { property: Property; className?: string }) => {
  const backgroundColor = property.userIconColourHex;
  const textColor = getFontColour(property.userIconColourHex);

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
      className={`${className} 
        sideways left-0 top-0 h-20 w-6 rounded-r-3xl text-center font-bold drop-shadow
      `}
    >
      {getPropertyOwnerInitials(property.userFullName)}
    </div>
  );
};

export const PropertyAddressData = ({
  property,
  className,
  userAction,
}: {
  property: Property;
  className?: string;
  userAction?: boolean;
}) => {
  const isMobile = useBelowTablet();
  return (
    <div className={` ${className}`}>
      <div className="flex w-full flex-row items-center ">
        <div className="relative mr-2 lg:mr-4">
          <MdHome className="text-blue-lightest dark:text-primary min-w-[30px]" size={isMobile ? 30 : 50} />
          {userAction && (
            <div className="bg-secondary-dark absolute right-0 top-0 flex size-[15px] items-center justify-center rounded-full p-0.5 text-white md:size-[22px] md:p-1">
              <MdOutlineNotifications size={isMobile ? 30 : 50} />
            </div>
          )}
        </div>
        <div>
          <p
            id={`${property.id}-line1-line2`}
          >{`${property.addressLine1}${property.addressLine2 ? `, ${property.addressLine2}` : ''}`}</p>
        </div>
      </div>
    </div>
  );
};

export const PropertyPostcodeData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`justify-center text-center ${className}`}>
      <div>
        <div>
          <p id={`${property.id}-postcode`}>{`${property.postcode}`}</p>
        </div>
      </div>
    </div>
  );
};

export const PropertyCurrentEpcData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`justify-center ${className}`}>
      <div>
        <p id={`${property.id}-currentEpc`}>{property.currentEPCRating ? property.currentEPCRating : 'N/a'}</p>
      </div>
    </div>
  );
};

export const PropertyPotentialEpcData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`justify-center ${className}`}>
      <div>
        <p id={`${property.id}-potentialEpc`}>{property.potentialEPCRating ? property.potentialEPCRating : 'N/a'}</p>
      </div>
    </div>
  );
};

export const PropertyStatusData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`justify-center ${className}`}>
      <div>
        <p id={`${property.id}-status`}>
          {property.jobStatus ? capitalize(JobStatusStrings[property.jobStatus]) : 'Unknown'}
        </p>
      </div>
    </div>
  );
};

export const PropertyLastActivityData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`justify-center ${className}`}>
      <p id={`${property.id}-statusUpdatedOn`}>{format(new Date(property.statusUpdatedOn), 'dd/MM/yyyy')}</p>
    </div>
  );
};

export const PropertyCreatedOnData = ({ property, className }: { property: Property; className?: string }) => {
  return (
    <div className={`justify-center ${className}`}>
      <p id={`${property.id}-createdOn`}>{format(new Date(property.createdOn), 'dd/MM/yyyy')}</p>
    </div>
  );
};
