import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loading } from '@epcbuilder/lib/components';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { postConfirmEmail } from '@/network/auth';

const ConfirmEmailForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [token, setToken] = useState<string | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (params.token) {
      setToken(params.token);
    }
  }, [params]);

  const handlePostConfirmEmail = useCallback(async () => {
    if (token) {
      try {
        await postConfirmEmail({ token });
        toast.success('Email successfully confirmed. Redirecting to login page', { toastId: 'confirm-email-success' });
        navigate('/');
      } catch (error: unknown) {
        const { detail } = error as AxiosErrorData;
        switch (detail) {
          case 'Token does not exist':
            toast.error('Please follow the link provided to you in your email and try again', {
              toastId: 'invalid-token-error',
            });
            setError(true);
            break;
          case 'Token has expired':
            toast.error('Token has expired. Redirecting to request a new confirmation email', {
              toastId: 'expired-token-error',
            });
            navigate('/resend-verification');
            break;
          default:
            setError(true);
            handleUnknownDetail(error);
            break;
        }
      }
    }
  }, [navigate, token]);

  useEffect(() => {
    if (token) {
      handlePostConfirmEmail();
    }
  }, [handlePostConfirmEmail, token]);

  if (error) {
    return (
      <div id="confirm-email-error" className="mt-8 flex flex-col items-center gap-4 text-center">
        <p className="text-white">There was an error trying to confirm your email.</p>
        <p className="mt-4 text-white">
          Please request another verification email{' '}
          <Link
            id="resend-verification-link"
            className="text-secondary underline hover:opacity-80"
            to="/resend-verification"
          >
            here
          </Link>
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="mt-8 flex flex-col items-center gap-4 text-center">
        <p className="text-color-inverse">Please wait while we confirm your email address...</p>
        <Loading />
      </div>
    </>
  );
};

export default ConfirmEmailForm;
