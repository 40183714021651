import React, { Dispatch, SetStateAction } from 'react';
import { MdCheck, MdHttps } from 'react-icons/md';
import { useBelowDesktop } from '@/hooks/media-queries/useMediaQueries';
import { EpcWizardSteps, EpcWizardStepsStrings } from '@/models/job';

const EpcWizardStepper = ({
  epcWizardSteps,
  active,
  setActive,
}: {
  epcWizardSteps: { value: EpcWizardSteps; locked: boolean; completed: boolean }[];
  active: EpcWizardSteps;
  setActive: Dispatch<SetStateAction<EpcWizardSteps>>;
}) => {
  const isMobile = useBelowDesktop();

  return (
    <div className="flex w-full flex-row px-8 pb-8 pt-12">
      {epcWizardSteps.map((step, index) => {
        const notLast = index !== epcWizardSteps.length - 1;
        const activeStepIndex = epcWizardSteps.findIndex((step) => step.value === active);
        const currentOrPreviousStep = active === step.value || activeStepIndex - 1 === index;
        const activeStep = active === step.value;
        const nextStepLocked = epcWizardSteps[index + 1]?.locked;

        return (
          <div
            className={`${notLast && (!isMobile || currentOrPreviousStep) && 'flex-1'} flex flex-row items-center`}
            key={index}
          >
            <button
              className={`${step.locked && 'cursor-default'} relative`}
              onClick={() => !step.locked && setActive(step.value)}
            >
              <p
                className={`${
                  activeStep && 'font-bold'
                } absolute -top-10 left-1/2 flex h-8 -translate-x-1/2 items-center text-center leading-none`}
              >
                {activeStep || !isMobile ? EpcWizardStepsStrings[step.value] : index + 1}
              </p>
              <div
                className={`${
                  !activeStep && `${step.locked ? 'border-primary/40' : 'border-primary'} size-6 border-2 p-0.5`
                } size-6 rounded-full`}
              >
                <div
                  className={`${
                    step.locked ? 'bg-transparent' : 'bg-primary'
                  } flex size-full items-center justify-center rounded-full`}
                >
                  {step.locked ? (
                    <MdHttps size={16} className="text-primary/40" />
                  ) : (
                    step.completed && <MdCheck size={16} className="text-color-inverse" />
                  )}
                </div>
              </div>
            </button>
            {notLast && (
              <div
                className={`${!isMobile || currentOrPreviousStep ? 'flex-1' : 'lg:32 w-4 sm:w-8 md:w-16'} ${
                  step.locked
                    ? 'bg-primary/40'
                    : nextStepLocked
                      ? 'from-primary to-primary/40 bg-gradient-to-r'
                      : 'bg-primary'
                } h-0.5`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default EpcWizardStepper;
