import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Loading } from '@epcbuilder/lib/components';
import { useJobContext } from '@/context/useJobContext';
import { useBelowLargeDesktop } from '@/hooks/media-queries/useMediaQueries';
import { getJobReport } from '@/network/jobs';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewer = () => {
  const notExtraLarge = useBelowLargeDesktop();
  const { job } = useJobContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const [file, setFile] = useState<File>();
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoadingMessage('Rendering Pages...');
  }, []);

  useEffect(() => {
    const getFile = async () => {
      setLoading(true);
      setLoadingMessage('Downloading Report');
      const response = await getJobReport({ id: job?.id });
      setFile(response?.file);
      setLoadingMessage('Loading Document');
    };

    getFile();
  }, [job]);

  return (
    <div className={`${loading ? 'overflow-hidden' : 'overflow-y-scroll'} relative size-full bg-white`}>
      {loading && (
        <div className="absolute left-0 top-0 z-10 flex size-full flex-col items-center justify-center gap-2 bg-white">
          <p className="text-dark font-bold">{loadingMessage}</p>
          <Loading />
        </div>
      )}
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={() => <></>} noData={() => <></>}>
        <div className="flex flex-col gap-4">
          {Array.from(new Array(numPages), (_, index) => (
            <Page
              className="mb-2 shadow-lg"
              key={`page_${index + 1}`}
              width={notExtraLarge ? 279 : 459}
              loading={() => <></>}
              noData={() => <></>}
              pageNumber={index + 1}
              onRenderSuccess={() => {
                if (index + 1 === numPages) {
                  setLoading(false);
                }
              }}
            />
          ))}
        </div>
      </Document>
    </div>
  );
};

export default PdfViewer;
