import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Modal, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { BookingSlot, SurveyBooking } from '@/models/job';
import { deleteSurveyBooking, patchSurveyBooking } from '@/network/surveys';

const BookingFormModal = ({
  date,
  slot,
  surveyId,
  onClose,
  surveyBookingId,
  refetchJobSurvey,
}: {
  date: string;
  slot: BookingSlot;
  surveyId: string;
  onClose: () => void;
  surveyBookingId: string;
  refetchJobSurvey: () => void;
}) => {
  const { register, handleSubmit, setError } = useForm<SurveyBooking>();
  const [loading, setLoading] = useState(false);

  const formatSurveyData = (data: SurveyBooking) => {
    const [date] = data.surveyDate.toString().split(' - ');
    const [day, month, year] = date.split('/');
    const formattedDate = new Date(`${year}-${month}-${day}`);
    const bookingSlot = slot;

    return {
      ...data,
      surveyDate: formattedDate,
      surveyId,
      bookingSlot,
      active: true,
    };
  };

  const handleFormSubmit: SubmitHandler<SurveyBooking> = async (data) => {
    setLoading(true);
    try {
      const formattedDate = formatSurveyData(data);
      const updatedFormData = { ...formattedDate, state: 2 };
      await patchSurveyBooking(surveyBookingId, updatedFormData);
      refetchJobSurvey();
      toast.success('Survey successfully booked.', { toastId: 'survey-booked' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors(setError, errors);
      handleUnknownDetail(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    if (surveyBookingId) {
      await deleteSurveyBooking({ id: surveyBookingId });
      refetchJobSurvey();
      onClose();
    }
  };

  return (
    <Modal id="booking-form-modal" onClose={handleCancel}>
      <h1 id="booking-modal-heading" className="font-header text-2xl">
        Book Survey
      </h1>
      <form className="mt-4 flex flex-col">
        <div className="relative mb-4">
          <p className="mb-2 ml-2 mt-4 font-bold">Selected Date</p>
          <TextInput {...register('surveyDate')} id="surveyDate" value={date} readOnly />
          <MdOutlineCalendarMonth size={20} className="text-color absolute right-4 top-[67px] -translate-y-1/2" />
        </div>
        <div className="mb-2">
          <p className="mb-2 ml-2 mt-4 font-bold">Notes</p>
          <textarea
            placeholder="Is there anything else you'd like us to know?"
            {...register('notes')}
            id="notes"
            className="text-color bg-color-secondary border-blue-lighter dark:border-primary-darker focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 py-1 text-base outline-none"
          />
        </div>
        <p className="ml-2 mt-2 font-bold">
          Your appointment is currently being held for you. <br />
          We’ll contact you soon to finalize the payment and secure your appointment.
        </p>
        <div className="mt-8 flex justify-between gap-4">
          <NewButton id="cancel-button" variant="secondary" text="Cancel" onClick={handleCancel} />
          <NewButton
            id="confirm-button"
            variant="primary"
            type="submit"
            loading={loading}
            text="Confirm"
            onClick={handleSubmit(handleFormSubmit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default BookingFormModal;
