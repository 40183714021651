import React from 'react';
import { InstallationStatus, JobInstallation } from '@epcbuilder/lib/models/jobs';
import { InstallationBooking } from '@/models/job';
import GenericNextStepsCard from '../GenericNextStepsCard';

interface NextStepsCardProps {
  jobInstallation?: JobInstallation;
  installationBooking?: InstallationBooking;
}

const NextStepsCard = ({ jobInstallation, installationBooking }: NextStepsCardProps) => {
  const isInstallationCompleted = jobInstallation?.installationStatusId === InstallationStatus.INSTALLATION_COMPLETED;
  const hasBookingDate = !!installationBooking?.bookingDateTime;

  const renderText = () => {
    if (isInstallationCompleted) {
      return 'Congratulations! Your installation has been completed';
    }
    if (hasBookingDate) {
      return 'Sit tight until our team gets in touch on the day of your installation!';
    }
    return 'Call to book your installation with us now!';
  };

  return (
    <GenericNextStepsCard>
      <p id="installation-next-steps-text" className="font-bold">
        {renderText()}
      </p>
    </GenericNextStepsCard>
  );
};

export default NextStepsCard;
