import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AuthLayout, CookieModal } from '@epcbuilder/lib/components';
import { LoginForm } from '@/components/login';

const LoginPage = () => {
  return (
    <>
      <CookieModal marketingUrl={import.meta.env.VITE_MARKETING_URL} />
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthLayout header="Login">
        <div className="mt-8 flex flex-row items-center">
          <p className="text-white">
            Don't have an account?{' '}
            <Link id="registration-link" className="text-link" to="/register">
              Get started now
            </Link>
          </p>
        </div>
        <LoginForm />
      </AuthLayout>
    </>
  );
};

export default LoginPage;
