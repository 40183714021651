import { Property } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getProperties } from '@/network/properties';

const useProperties = ({
  currentEpcFilters,
  potentialEpcFilters,
  jobStatusFilters,
  search,
  limit,
  page,
}: {
  currentEpcFilters: number[];
  potentialEpcFilters: number[];
  jobStatusFilters: number[];
  search: string;
  limit?: number;
  page: number;
}) => {
  const { data, error, mutate, isLoading } = useSWR<Property[]>(
    `properties-data-${currentEpcFilters}-${potentialEpcFilters}-${jobStatusFilters}-${search}`,
    async () =>
      await getProperties({
        currentEpcFilters,
        potentialEpcFilters,
        jobStatusFilters,
        search,
      })
  );

  const sortedAndFilteredProperties = data
    ? data
        .sort((propertyA, propertyB) => {
          if (propertyA.requiresUserAction && !propertyB.requiresUserAction) return -1;
          if (!propertyA.requiresUserAction && propertyB.requiresUserAction) return 1;

          const dateA = new Date(propertyA.statusUpdatedOn).getTime();
          const dateB = new Date(propertyB.statusUpdatedOn).getTime();
          return dateB - dateA;
        })
        .slice(limit ? (page - 1) * limit : 0, limit ? page * limit : undefined)
    : [];

  return {
    properties: sortedAndFilteredProperties,
    error,
    mutate,
    isLoading,
    total: data?.length || 0,
  };
};

export default useProperties;
