import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, SensitiveTextInput } from '@epcbuilder/lib/components';
import { ResetPassword } from '@epcbuilder/lib/models/auth';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { handleFormErrors } from '@epcbuilder/lib/utils/form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { postResetPassword } from '@/network/auth';

const resetPasswordSchema = yup.object().shape({
  password: yup.string().required('Password must not be empty').min(6, 'Password must be at least 6 characters long'),
  confirmPassword: yup
    .string()
    .required('Confirm Password must not be empty')
    .oneOf([yup.ref('password'), ''], 'Confirm Password must match Password'),
});

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const defaultValues: ResetPassword = {
    token: '',
    password: '',
    confirmPassword: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setValue,
  } = useForm<ResetPassword>({
    defaultValues,
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ResetPassword> = async (data) => {
    try {
      await postResetPassword(data);
      toast.success('Password reset success. Redirecting to login.', { toastId: 'reset-password-success' });
      navigate('/');
    } catch (error: unknown) {
      const { errors, detail } = error as AxiosErrorData;
      handleFormErrors<ResetPassword>(setError, errors);

      switch (detail) {
        case 'Token does not exist':
          toast.error('Please follow the link provided to you in your email and try again', {
            toastId: 'invalid-token-error',
          });
          break;
        case 'Token has expired':
          toast.error('Token has expired. Redirecting to request a new password reset email', {
            toastId: 'expired-token-error',
          });
          navigate('/forgot-password');
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  useEffect(() => {
    if (params.token) {
      setValue('token', params.token);
    }
  }, [params, setValue]);

  return (
    <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <SensitiveTextInput
        {...register('password')}
        id="password"
        name="password"
        title="Your new password"
        placeholder="New Password"
        error={errors.password?.message}
      />
      <SensitiveTextInput
        {...register('confirmPassword')}
        id="confirmPassword"
        name="confirmPassword"
        title="Confirm your new password"
        placeholder="Confirm New Password"
        error={errors.confirmPassword?.message}
      />
      <Button id="reset-password-submit" loading={isSubmitting} type="submit">
        Submit
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
