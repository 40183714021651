import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Card, Loading } from '@epcbuilder/lib/components';
import { JobSurvey, SurveyStatus } from '@epcbuilder/lib/models/jobs';
import { addWeekdayDays, addWeekdayMonths, subtractWeekdayDays } from '@epcbuilder/lib/utils/datetime';
import { addDays, differenceInDays, format, isAfter, isBefore, isSameDay, isWeekend } from 'date-fns';
import { useBelowDesktop } from '@/hooks/media-queries/useMediaQueries';
import useSurveyBooking from '@/hooks/surveys/useSurveyBooking';
import useSurveyBookingAvailableSlots from '@/hooks/surveys/useSurveyBookingAvailableSlots';
import { BookingSlot, EligibilityStatus } from '@/models/job';
import { deleteSurveyBooking, putSurveyBooking } from '@/network/surveys';
import BookingConfirmationModal from './modals/BookingConfirmationModal';
import BookingFormModal from './modals/BookingFormModal';
import BookingSlotCalendar from './BookingSlotCalendar';

const formatSurveyDate = (surveyDate: Date, bookingSlot: BookingSlot): string => {
  const slot = bookingSlot === 1 ? 'AM' : 'PM';
  return `${format(surveyDate, 'dd/MM/yyyy')} - ${slot}`;
};

const SurveyMessage = ({ message, children }: { message: string; children?: React.ReactNode }) => {
  return (
    <div
      id="survey-booked-overlay"
      className="dark:bg-dark/60 absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]"
    >
      <div className="bg-blue dark:bg-primary-dark/90 border-primary-lighter dark:border-primary max-w-[220px] rounded-[22px] border-2 p-4 shadow-lg">
        <p id="survey-booked-message" className="w-full text-center font-bold">
          {message}
        </p>
        {children}
      </div>
    </div>
  );
};

const BookingPickerCard = ({
  jobSurvey,
  jobEligibilityStatus,
  refetchJobSurvey,
}: {
  jobSurvey: JobSurvey;
  jobEligibilityStatus: EligibilityStatus | undefined;
  refetchJobSurvey: () => void;
}) => {
  const isMobile = useBelowDesktop();
  const DAYS_TO_SHOW = useMemo(() => (isMobile ? 3 : 5), [isMobile]);
  const FIRST_DATE = useMemo(() => addWeekdayDays(new Date(new Date().setHours(0, 0, 0, 0)), 1), []);
  const LAST_DATE = useMemo(() => addWeekdayMonths(FIRST_DATE, 3), [FIRST_DATE]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [start, setStart] = useState<Date>(FIRST_DATE);
  const [end, setEnd] = useState<Date>(addWeekdayDays(FIRST_DATE, DAYS_TO_SHOW - 1));
  const [loading, setLoading] = useState(true);
  const [selectedDateSlot, setSelectedDateSlot] = useState<string | null>();
  const [selectedSlot, setSelectedSlot] = useState<BookingSlot | null>();
  const [surveyBookingId, setSurveyBookingId] = useState<string | null>(null);

  const { availableSlots } = useSurveyBookingAvailableSlots({ start, end });
  const { surveyBooking, isLoading } = useSurveyBooking({ id: jobSurvey?.id });

  useEffect(() => {
    setStart(FIRST_DATE);
    setEnd(addWeekdayDays(FIRST_DATE, DAYS_TO_SHOW - 1));
  }, [DAYS_TO_SHOW, FIRST_DATE]);

  useEffect(() => {
    if (surveyBooking && surveyBooking.state === 1) {
      const formattedDate = formatSurveyDate(new Date(surveyBooking.surveyDate), surveyBooking.bookingSlot);
      setSelectedDateSlot(formattedDate);
      setSelectedSlot(surveyBooking.bookingSlot);
      setSurveyBookingId(surveyBooking.id);
    }
  }, [surveyBooking]);

  useEffect(() => {
    if (availableSlots) setLoading(false);
  }, [availableSlots]);

  const weekdayDates = useMemo(() => {
    return Array.from({ length: differenceInDays(end, start) + 1 }, (_, index) => addDays(start, index)).filter(
      (date) => !isWeekend(date)
    );
  }, [end, start]);

  const updateDates = useCallback(
    (newStart: Date) => {
      setStart(newStart);
      setEnd(addWeekdayDays(newStart, DAYS_TO_SHOW - 1));
    },
    [DAYS_TO_SHOW]
  );

  const handleForwards = useCallback(
    () => updateDates(addWeekdayDays(start, DAYS_TO_SHOW)),
    [DAYS_TO_SHOW, start, updateDates]
  );
  const handleBackwards = useCallback(
    () => updateDates(subtractWeekdayDays(start, DAYS_TO_SHOW)),
    [DAYS_TO_SHOW, start, updateDates]
  );

  const handleCancelBooking = async () => {
    if (surveyBooking) {
      await deleteSurveyBooking({ id: surveyBooking.id });
      refetchJobSurvey();
      toast.success('Survey booking successfully cancelled.', { toastId: 'survey-booking-cancelled' });
      setShowConfirmationModal(false);
    }
  };

  const handleSlotSelect = async (date: Date, slot: BookingSlot) => {
    const formattedDate = formatSurveyDate(new Date(date), slot);
    setSelectedDateSlot(formattedDate);
    setSelectedSlot(slot);

    const response = await putSurveyBooking({
      surveyId: jobSurvey.id,
      surveyDate: date,
      slot,
      state: 1,
      active: true,
    });

    if (response) {
      setSurveyBookingId(response.data);
    }
  };

  const renderBlockPickerMessage = useCallback(() => {
    const { surveyStatusID } = jobSurvey;
    let message = null;
    let action = null;

    if (!jobSurvey.jobActive) return <SurveyMessage message="Job is no longer active." />;

    switch (surveyStatusID) {
      case SurveyStatus.SURVEY_CANCELLED:
        return;
      case SurveyStatus.REPORT_COMPLETED:
        return <SurveyMessage message="Your report has been completed." />;
      case SurveyStatus.REPORT_IN_PROGRESS:
        return <SurveyMessage message="Your survey has been completed and your report is in progress." />;
      case SurveyStatus.SURVEY_COMPLETED:
        return <SurveyMessage message="Your survey has been completed." />;
      case SurveyStatus.SURVEY_BOOKED:
        message = 'Your survey is booked. To reschedule, please cancel your current appointment first.';
        action = !isLoading && (
          <button
            id="cancel-booking-button"
            onClick={() => setShowConfirmationModal(true)}
            className="text-dark border-primary-lighter dark:bg-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest mt-2 h-12 w-full rounded-[24px] border-2 bg-white px-4 font-bold dark:border-none"
          >
            Cancel Booking
          </button>
        );
        return <SurveyMessage message={message}>{action}</SurveyMessage>;
      case SurveyStatus.SURVEY_REQUESTED:
        return (
          <SurveyMessage message="We have received your requested booking slot and will be in touch to confirm your booking." />
        );
      default:
        break;
    }

    if (jobEligibilityStatus?.eligibility) {
      message = 'You are eligible for free home improvement upgrades.';
    }

    return message ? <SurveyMessage message={message} /> : <></>;
  }, [jobEligibilityStatus, jobSurvey, isLoading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className="relative lg:max-w-none">
      {renderBlockPickerMessage()}
      <div
        className={`${(jobEligibilityStatus?.eligibility || jobSurvey.surveyStatusID === 3) && 'opacity-50'} flex min-w-[294px] flex-col`}
      >
        <div className="bg-blue dark:bg-primary w-full rounded-[12px]">
          <div className="relative flex flex-row items-center justify-center p-2">
            <button
              type="button"
              className="absolute left-2 flex h-full items-center justify-center"
              onClick={handleBackwards}
              disabled={isSameDay(start, FIRST_DATE) || isBefore(start, FIRST_DATE)}
            >
              <MdKeyboardArrowLeft
                size={22}
                className={`${(isSameDay(start, FIRST_DATE) || isBefore(start, FIRST_DATE)) && 'text-blue dark:text-primary'}`}
              />
            </button>
            <div className="flex flex-row items-center gap-4">
              {weekdayDates.map((date, index) => (
                <div id={`weekday-${index}`} key={index} className="flex w-[70px] flex-col items-center">
                  <p className="font-bold">{format(date, 'EEE')}</p>
                  <p className="font-bold">{format(date, 'd/M')}</p>
                </div>
              ))}
            </div>
            <button
              type="button"
              className="absolute right-2 flex h-full items-center justify-center"
              onClick={handleForwards}
              disabled={isSameDay(end, LAST_DATE) || isAfter(end, LAST_DATE)}
            >
              <MdKeyboardArrowRight
                size={22}
                className={`${(isSameDay(end, LAST_DATE) || isAfter(end, LAST_DATE)) && 'text-blue dark:text-primary'}`}
              />
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-2 px-2">
          <div className="border-blue dark:border-primary-dark h-full border-r-[2px]" />
          {weekdayDates.map((date) => (
            <BookingSlotCalendar
              key={date.toString()}
              date={date.toString()}
              availableSlots={availableSlots}
              onSlotSelect={handleSlotSelect}
            />
          ))}
        </div>
      </div>
      <p>
        Choose your preferred date and time for the Energy Assessment.
        <br />
        Please note there is an upfront charge of £195 (inc VAT) for the assessment which will be deducted from the cost
        of the installation.
      </p>
      {showConfirmationModal && surveyBooking && (
        <BookingConfirmationModal
          message="You are about to cancel the booking on "
          value={surveyBooking}
          onClose={() => setShowConfirmationModal(false)}
          callback={handleCancelBooking}
          refetchJobSurvey={refetchJobSurvey}
        />
      )}
      {selectedDateSlot && selectedSlot ? (
        <BookingFormModal
          date={selectedDateSlot}
          slot={selectedSlot}
          onClose={() => setSelectedDateSlot(null)}
          surveyId={jobSurvey.id}
          surveyBookingId={surveyBookingId as string}
          refetchJobSurvey={refetchJobSurvey}
        />
      ) : undefined}
    </Card>
  );
};

export default BookingPickerCard;
