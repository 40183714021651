import React, { useState } from 'react';
import { Modal } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { format } from 'date-fns';
import { SurveyBooking } from '@/models/job';

export const BookingConfirmationModal = ({
  message = '',
  value,
  onClose,
  callback,
  refetchJobSurvey,
}: {
  message?: string;
  value?: SurveyBooking;
  onClose: () => void;
  callback: () => void;
  refetchJobSurvey: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      callback();
      refetchJobSurvey();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal id="booking-confirmation-modal" onClose={onClose}>
      <h1 className="font-header text-lg">Are you sure?</h1>
      <p className="mt-4">
        {message}
        <span className="font-bold">
          {value && format(new Date(value.surveyDate), 'dd/MM/yyyy')}
          {value && (value.bookingSlot === 1 ? ' AM' : ' PM')}
        </span>
        .
      </p>
      <div className="mt-6 flex flex-row justify-between gap-4">
        <NewButton
          id="cancel-button"
          onClick={() => {
            onClose();
            refetchJobSurvey();
          }}
          variant="secondary"
          text="Cancel"
        />
        <NewButton id="confirm-button" variant="primary" loading={loading} onClick={handleConfirm} text="Confirm" />
      </div>
    </Modal>
  );
};

export default BookingConfirmationModal;
