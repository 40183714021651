import React from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { ISortConfig } from './JobHistory';

const JobHistoryHeader = ({
  sortConfig,
  setSortConfig,
}: {
  sortConfig: ISortConfig;
  setSortConfig: React.Dispatch<React.SetStateAction<ISortConfig>>;
}) => {
  const handleSort = (column: string) => {
    let direction: string = 'desc';
    if (sortConfig.column === column && sortConfig.direction === 'desc') {
      direction = 'asc';
    }

    setSortConfig({ column, direction });
  };

  const renderSortIcon = (column: string) => {
    if (sortConfig.column === column) {
      return sortConfig.direction === 'asc' ? <MdArrowDropDown size={20} /> : <MdArrowDropUp size={20} />;
    }
    return <MdArrowDropDown size={20} />;
  };

  return (
    <div className="border-blue-lightest mt-6 hidden border-b-2 lg:flex">
      <div className="mr-4 inline-block w-[1rem] sm:w-[2rem] md:mr-0"></div>
      <div className="w-full">
        <div className="flex justify-between pb-2">
          <div className="flex w-[11rem] justify-start md:w-[15rem] xl:w-[20rem]">
            <p className="text-[12px] font-bold uppercase">Property Address</p>
          </div>
          <div className="flex w-[8rem] justify-center">
            <p className="text-[12px] font-bold uppercase">Postcode</p>
          </div>
          <div className="hidden w-[8rem] justify-center xl:flex">
            <p className="text-[12px] font-bold uppercase">Status</p>
          </div>
          <div className="hidden w-[8rem] justify-center md:flex">
            <p className="text-[12px] font-bold uppercase">EPC Rating</p>
          </div>
          <button
            className="hidden w-[8rem] cursor-pointer justify-center md:flex"
            onClick={() => handleSort('createdOn')}
          >
            <p className="flex items-center text-[12px] font-bold uppercase">
              Created On
              {renderSortIcon('createdOn')}
            </p>
          </button>
          <button
            className="mr-4 hidden w-[8rem] cursor-pointer justify-center lg:flex"
            onClick={() => handleSort('statusUpdatedOn')}
          >
            <p className="flex items-center text-[12px] font-bold uppercase">
              Last Updated
              {renderSortIcon('statusUpdatedOn')}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobHistoryHeader;
