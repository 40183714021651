import React, { useCallback, useState } from 'react';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IconButton } from '@epcbuilder/lib/components';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { Company } from '@epcbuilder/lib/models/companies';
import { Property } from '@epcbuilder/lib/models/properties';
import { deleteProperty } from '@/network/properties';
import {
  PropertyAddressData,
  PropertyCurrentEpcData,
  PropertyLastActivityData,
  PropertyOwnerInitialsData,
  PropertyPostcodeData,
  PropertyPotentialEpcData,
  PropertyStatusData,
} from './PropertyData';

const PropertyRow = ({
  property,
  company,
  refetch,
}: {
  property: Property;
  company: Company | undefined;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const userAction = property.requiresUserAction;
  const navigateToProperty = () => {
    navigate(`/properties/${property.id}`);
  };
  const navigateToProperyKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      navigateToProperty();
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleDeleteProperty = useCallback(async () => {
    try {
      await deleteProperty({ id: property.id });
      toast.success('Property successfully deleted', { toastId: 'delete-success' });
      refetch();
    } catch (error: unknown) {
      toast.error('There was a problem deleting Property. Please try again.');
    }
  }, [refetch, property]);

  return (
    <>
      <div
        id={property.id}
        className={`${userAction ? 'dark:bg-dark-dark bg-[#EEFAF9]' : 'bg-color'} border-1 shadow-grey-sm dark:shadow-dark-sm dark:hover:shadow-brand-sm hover:shadow-brand-sm my-4 flex
              w-full flex-row items-center rounded-3xl duration-100`}
      >
        <div className="mr-4 flex w-[1rem] items-center sm:w-[4rem] md:mr-0">
          {company && company.id > 0 && <PropertyOwnerInitialsData property={property} />}
        </div>
        <div
          tabIndex={0}
          className="flex h-20 w-full flex-row"
          onClick={navigateToProperty}
          onKeyDown={navigateToProperyKeyPress}
          role="button"
        >
          <div className="flex w-full flex-row items-center justify-between">
            <PropertyAddressData
              property={property}
              userAction={userAction}
              className="w-[11rem] md:w-[12rem] xl:w-[20rem]"
            />
            <PropertyPostcodeData property={property} className="xs:w-[5rem] sm:w-[8rem]" />
            <PropertyPotentialEpcData property={property} className="hidden w-[8rem] xl:flex" />
            <PropertyCurrentEpcData property={property} className="hidden w-[8rem] md:flex" />
            <PropertyStatusData property={property} className="hidden w-[8rem] md:flex" />
            <PropertyLastActivityData property={property} className="hidden w-[8rem] lg:flex" />
          </div>
        </div>
        <div className="mr-4 flex w-[3rem] items-center">
          <IconButton
            className="border-none bg-transparent shadow-none"
            id={`${property.id}-delete`}
            style="delete"
            onClick={() => setShowDeleteModal(true)}
          >
            <MdOutlineDeleteForever size={24} className="text-error-light" />
          </IconButton>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteModal
          callback={handleDeleteProperty}
          onClose={() => setShowDeleteModal(false)}
          value={`${property.addressLine1}, ${property.addressLine2 ? `${property.addressLine2},` : ''} ${property.city}, ${property.postcode}`}
        />
      )}
    </>
  );
};

export default PropertyRow;
