import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { FiltersContainer } from '@epcbuilder/lib/components';
import { NewSelect } from '@epcbuilder/lib/components/Select';
import { jobStatusOptions } from '@epcbuilder/lib/models/jobs';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';

const FiltersForm = <T extends FieldValues>({ control, reset }: { control: Control<T>; reset: () => void }) => {
  return (
    <FiltersContainer id="properties-filters" reset={reset}>
      <div className="mt-4 w-full md:mt-0">
        <NewSelect
          multiple={true}
          control={control}
          id="epcCurrent"
          name={'currentEpcFilters' as Path<T>}
          title="Filter by current EPC rating"
          placeholder="Filter Current EPC"
          options={epcRatingOptions}
        />
      </div>
      <div className="w-full">
        <NewSelect
          multiple={true}
          control={control}
          id="jobStatus"
          name={'jobStatusFilters' as Path<T>}
          title="Filter by status"
          placeholder="Filter Job Status"
          options={jobStatusOptions}
        />
      </div>
    </FiltersContainer>
  );
};

export default FiltersForm;
