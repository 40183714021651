import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Loading } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';
import { ProfileForm } from '@/components/profile';
import AccountSecurity from '@/components/profile/AccountSecurity';
import ProfileSideBar from '@/components/profile/ProfileSideBar';
import withAuth from '@/hoc/withAuth';
import useUser from '@/hooks/auth/useUser';
import useUserCompany from '@/hooks/company/useUserCompany';

const ProfilePage = () => {
  const { user } = useUser();
  const { company } = useUserCompany();

  const [activeTab, setActiveTab] = useState('profile');

  const renderContent = () => {
    if (!user) {
      return <Loading />;
    }

    switch (activeTab) {
      case 'profile':
        return <ProfileForm id="profile-page" user={user} company={company} />;
      case 'accountSecurity':
        return <AccountSecurity id="profile-account-page" />;
      default:
        return <ProfileForm id="profile-page" user={user} company={company} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="EPC Builder Profile">
        <div id="profile-container" className="flex flex-col pb-6 sm:p-8">
          <Container>
            {!user ? (
              <Loading />
            ) : (
              <div className="sm:shadow-grey-sm sm:dark:shadow-dark-sm sm:dark:bg-dark-dark flex flex-col justify-center rounded-[20px] border-2 border-none bg-none sm:h-full sm:max-h-[740px] sm:flex-row sm:bg-[#F5FFFD]">
                <ProfileSideBar setActiveTab={setActiveTab} activeTab={activeTab} />
                <div className="mt-6 w-full px-2 sm:mr-4 sm:mt-0 sm:px-8 sm:py-10 sm:pb-8">
                  <div className="flex flex-col">{renderContent()}</div>
                </div>
              </div>
            )}
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default withAuth(ProfilePage);
