import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, SensitiveTextInput } from '@epcbuilder/lib/components';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ChangePassword } from '@/models/user';
import { patchPassword } from '@/network/profile';

const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required('Old Password must be filled'),
  newPassword: yup
    .string()
    .required('New Password must not be empty')
    .min(6, 'New Password must be at least 6 characters long')
    .notOneOf([yup.ref('oldPassword')], 'New Password cannot be the same as the Current Password'),
  confirmNewPassword: yup
    .string()
    .required('Confirm New Password must not be empty')
    .oneOf([yup.ref('newPassword'), ''], 'Confirm New Password must match New Password'),
});

const ChangePasswordModal = ({ onClose }: { onClose: () => void }) => {
  const defaultValues: ChangePassword = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ChangePassword>({
    defaultValues,
    resolver: yupResolver(changePasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ChangePassword> = async (data) => {
    try {
      await patchPassword(data);
      toast.success('Password successfully updated', { toastId: 'change-password-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ChangePassword>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal id="change-password-modal" onClose={onClose}>
      <h1>Change Password</h1>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <SensitiveTextInput
          {...register('oldPassword')}
          id="oldPassword"
          name="oldPassword"
          title="Your old password"
          placeholder="Current password"
          error={errors.oldPassword?.message}
        />
        <SensitiveTextInput
          {...register('newPassword')}
          id="newPassword"
          name="newPassword"
          title="Your new password"
          placeholder="New Password"
          error={errors.newPassword?.message}
        />
        <SensitiveTextInput
          {...register('confirmNewPassword')}
          id="confirmNewPassword"
          name="confirmNewPassword"
          title="Confirm your new password"
          placeholder="Confirm New Password"
          error={errors.confirmNewPassword?.message}
        />
        <Button id="change-password-submit" loading={isSubmitting} type="submit">
          Save changes
        </Button>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
