import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { FiltersContainer, NewSelect } from '@epcbuilder/lib/components';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';

const FiltersForm = <T extends FieldValues>({ control, reset }: { control: Control<T>; reset: () => void }) => {
  return (
    <FiltersContainer id="properties-filters" reset={reset}>
      <div className="mt-4 w-full sm:w-1/2 md:mt-0">
        <NewSelect
          multiple={true}
          control={control}
          id="epcCurrent"
          name={'currentEpcFilters' as Path<T>}
          title="Filter by current EPC rating"
          placeholder="Filter Current EPC"
          options={epcRatingOptions}
        />
      </div>
    </FiltersContainer>
  );
};

export default FiltersForm;
