import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';

const BookingNotesModal = ({
  onClose,
  initialNotes,
  onSave,
}: {
  onClose: () => void;
  initialNotes: string;
  onSave: (notes: string) => void;
}) => {
  const { register, handleSubmit } = useForm<{ notes: string }>({
    defaultValues: { notes: initialNotes },
  });

  const handleSave = (data: { notes: string }) => {
    onSave(data.notes);
  };

  return (
    <Modal id="booking-notes-modal" onClose={onClose}>
      <h1 className="font-header text-2xl" id="booking-notes-heading">
        Booking Notes
      </h1>
      <form className="mt-4 flex flex-col">
        <div className="flex flex-col">
          <div className="mb-4">
            <p className="mb-2 ml-2 mt-4 font-bold">Notes</p>
            <textarea
              placeholder="Is there anything else you'd like us to know?"
              {...register('notes')}
              id="notes"
              className="text-color bg-color-secondary border-blue-lighter dark:border-primary-darker focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 py-1 text-base outline-none"
            />
          </div>
          <div className="flex justify-end gap-2">
            <NewButton id="cancel-button" variant="secondary" text="Cancel" onClick={onClose} />
            <NewButton
              id="confirm-button"
              type="submit"
              variant="primary"
              text="Confirm"
              onClick={handleSubmit(handleSave)}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default BookingNotesModal;
