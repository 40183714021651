import React from 'react';
import { MdOutlineSaveAlt } from 'react-icons/md';
import { Card } from '@epcbuilder/lib/components';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import download from 'downloadjs';
import { useJobContext } from '@/context/useJobContext';
import { useBelowLargeDesktop } from '@/hooks/media-queries/useMediaQueries';
import { getJobReport } from '@/network/jobs';

const DownloadPdfCard = () => {
  const notExtraLarge = useBelowLargeDesktop();
  const { job } = useJobContext();

  const downloadPdf = async () => {
    try {
      const pdfData = await getJobReport({ id: job?.id });

      download(pdfData?.file, pdfData?.fileName, 'application/pdf');
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  return (
    <Card onClick={downloadPdf}>
      <div className="flex size-full flex-row items-center justify-center gap-2 lg:flex-col" id="download-pdf-button">
        <MdOutlineSaveAlt size={notExtraLarge ? 18 : 64} className={`${!notExtraLarge && 'text-blue'}`} />
        <p className="text-center font-bold">Download</p>
      </div>
    </Card>
  );
};

export default DownloadPdfCard;
