import React from 'react';
import { MdOutlinePhone, MdOutlinePhoneCallback } from 'react-icons/md';
import { Card } from '@epcbuilder/lib/components';

export const CallUsCard = () => {
  return (
    <Card>
      <div id="call-us-now-button" className="flex h-full flex-col items-center justify-center gap-2">
        <MdOutlinePhone size={50} className="text-blue" />
        <p className="text-center font-bold">Call us now</p>
      </div>
    </Card>
  );
};

export const RequestCallbackCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <Card onClick={onClick}>
      <div id="request-callback-button" className="flex h-full flex-col items-center justify-center gap-2">
        <MdOutlinePhoneCallback size={50} className="text-blue" />
        <p className="text-center font-bold">Request a callback</p>
      </div>
    </Card>
  );
};
