import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowLeft } from 'react-icons/md';
import { Button, Card, Modal } from '@epcbuilder/lib/components';
import { InstallationStatusStrings, JobInstallation } from '@epcbuilder/lib/models/jobs';
import { format } from 'date-fns';
import { useBelowDesktop } from '@/hooks/media-queries/useMediaQueries';
import { InstallationBooking } from '@/models/job';

const InstallationDetailsCard = ({
  jobInstallation,
  installationBooking,
}: {
  jobInstallation: JobInstallation;
  installationBooking: InstallationBooking | undefined;
}) => {
  const isMobile = useBelowDesktop();

  const [showInstallationNotes, setShowInstallationNotes] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);

  return (
    <Card>
      <div className="flex flex-col gap-2">
        <p className="pl-4">Install status:</p>
        <div className="bg-blue dark:bg-primary-dark flex items-center justify-center rounded-[16px] p-4">
          <p className="text-center font-bold">{InstallationStatusStrings[jobInstallation.installationStatusId]}</p>
        </div>
      </div>
      <div className="flex flex-col gap-2 pl-4">
        <div className="flex flex-row">
          <p className="flex-1">Installer:</p>
          <p id="installer-name" className="flex-1 truncate font-bold">
            {installationBooking?.assessor || '-'}
          </p>
        </div>
        <div className="flex flex-row">
          <p className="flex-1">Date:</p>
          <p id="installer-date" className="flex-1 font-bold">
            {installationBooking?.bookingDateTime
              ? format(new Date(installationBooking?.bookingDateTime), 'dd/MM/yyyy')
              : '-'}
          </p>
        </div>
        <div className="flex flex-row">
          <p className="flex-1">Time:</p>
          <p id="installer-time" className="flex-1 font-bold">
            {installationBooking?.bookingDateTime
              ? format(new Date(installationBooking?.bookingDateTime), 'HH:mm')
              : '-'}
          </p>
        </div>
      </div>
      {isMobile ? (
        <>
          <button
            id="notes-button"
            type="button"
            onClick={() => setShowInstallationNotes(!showInstallationNotes)}
            className="flex flex-row items-center justify-between"
          >
            <p id="notes-text" className="pl-4 font-bold">
              Notes for installer
            </p>
            {showInstallationNotes ? <MdKeyboardArrowDown size={20} /> : <MdKeyboardArrowLeft size={20} />}
          </button>
          {(showInstallationNotes || !isMobile) && <div className="h-8 w-full">{installationBooking?.notes}</div>}
        </>
      ) : (
        <Button disabled={!installationBooking?.notes} onClick={() => setShowNotesModal(true)}>
          Notes
        </Button>
      )}
      {showNotesModal && (
        <Modal onClose={() => setShowNotesModal(false)}>
          <div className="flex flex-col">
            <p className="text-lg font-bold">Booking Notes:</p>
            <p id="installation-notes">{installationBooking?.notes}</p>
          </div>
        </Modal>
      )}
    </Card>
  );
};

export default InstallationDetailsCard;
